// ** Checks if an object is empty (returns boolean)
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import firebase from '@firebase/app';
import { NotificationManager } from "react-notifications";
import { encryptRequestMiddleware } from './encryptRequestMiddleware';
import { decryptResponseMiddleware } from './decryptResponseMiddleware';


export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

const MySwal = withReactContent(Swal)
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: 'login session has been expired ?',
    text: "login again to continue accessing your account !",
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    confirmButtonText: 'Yes, logout it!', //how to add css to this button?
    customClass: {
      btn : 'btn btn-danger',
      confirmButton: "btn btn-success",
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    allowOutsideClick: false,
    buttonsStyling: true,

  }).then(function (result) {
    if (result.value) {
      firebase
        .auth()
        .signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/"
    }
  })

}
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
  if (process.env.REACT_APP_ENCRYPTION === "false") {
    config.headers.Encryption = process.env.REACT_APP_ENCRYPTION; // for  encripttion  and decription mathod
  } else {
    const isFormData = config.headers['Content-Type'] === 'multipart/form-data';
    if (!isFormData && (config.method === 'put' || config.method === 'post' || config.method === 'patch') && config.data) {
      const encrypted = await encryptRequestMiddleware(config.data)
      config.data = encrypted;
    }
  }
  return config;
});
Axios.interceptors.response.use(
  response => {
    if (process.env.REACT_APP_ENCRYPTION === "false") {
      return response;
    }
    // Check content type
    const contentType = response.headers['content-type'];
    if (contentType && contentType.includes('application/json')) {
      response.data = JSON.parse(decryptResponseMiddleware(response.data));
    }

    return response;
  },
  error => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      if (process.env.REACT_APP_ENCRYPTION === "false") {
        return error?.response;
      }
      // Check content type
      const contentType = error.response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
       
        error.response.data = JSON.parse(decryptResponseMiddleware(error.response.data));
      }
      return error?.response
      // return Promise.reject(error?.response ?? error);
    }

  }
);

// Axios.interceptors.request.use(async (config) => {
//   await new Promise((resolve) => {
//     const unsubscribe = firebase.auth().onAuthStateChanged(() => {
//       unsubscribe();
//       resolve();
//     });
//   });
//   config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
//   return config;
// });
// Axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (firebase.auth().currentUser === null || error.response.status === 401) {
//       handleConfirmText();
//     } else {
//       return Promise.reject(error);
//     }

//   }
// );

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export { Axios, AxiosInstance }


export const uploadImageToS3 = (file, setisLoader, foldername, height, width) => {
  const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG , PDF file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  setisLoader(true);
  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, setisLoader, foldername)
      .then((imageData) => {
        setisLoader(false);
        let fileUrl = imageData; 
        if (process.env.REACT_APP_PUBLIC_UPLOAD_MODE === "AZURE" && process.env.NODE_ENV !== "development") {
          fileUrl = imageData.replace(/^https:\/\/[^/]+/, "https://media.sra.gov.in"); 
        }
        resolve(fileUrl);
      })
      .catch((error) => {
        setisLoader(false);
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};

export const  deleteImageFromS3 = async (e,filepath, setIsLoading) => {
  if (process.env.REACT_APP_PUBLIC_UPLOAD_MODE === "AZURE") {
    try {
      const response = await Axios.post(`/api/presigned-azure/generate-presigned-url-for-delete`, {
        filePath: [filepath]
      });

      if (!response.data.status || !Array.isArray(response.data.data)) {
        console.error("Invalid response format", response.data);
        return "";
      }

      const presignedUrls = response.data.data;
      const deleteResponses = await Promise.all(
        presignedUrls.map(async (url) => {
          try {
            const deleteResponse = await axios.delete(url, {
              paramsSerializer: params => params, // Prevent URL encoding of existing params
              headers: {
                'x-ms-version': '2020-08-04', // Required Azure version header
                'Content-Type': 'application/octet-stream' // Required content type
              }
            });

            return deleteResponse.status === 204 ? true : false;
          } catch (error) {
            console.error("Error deleting file from Azure", error);
            return false;
          }
        })
      );

      return deleteResponses.every((status) => status); // Returns true if all files are deleted successfully
    } catch (error) {
      console.error("Error generating presigned URL:", error);
      return "";
    }
  } else {
  try {
    const s3UrlPrefix = "https://sra-automation.s3.ap-south-1.amazonaws.com/";
    const filePath = filepath.replace(s3UrlPrefix, "");

    const response = await Axios.post(`api/presigned/generate-presigned-url-for-delete`, {
      filePath: filePath
    });

    if (response.data.status === false) {
      return ""
    } else {
      const presignedUrl = response.data.data;
      try {
        const response = await axios.delete(presignedUrl);
        if (response.status === 204) {
          console.log("File deleted successfully");
          return response
        } else {
          console.error("Failed to delete file", response);
          return ""
        }
      } catch (error) {
        console.error("Error deleting file from S3", error);
        return ""
      }
    }
  } catch (error) {
    console.error("Error uploading file or generating presigned URL:", error);
    return ""
  }
  }
};

//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};



/********************upload video function for form information master**************/
export const UPLOAD_VIDEOS = async (
  file,
  setIsLoading,
  folderName
) => {
  setIsLoading(true);

  if (file.type.includes("video/")) {

      const isVideo = file.type.includes("video/");
      if (isVideo && file.size <= 51000000) {

        return new Promise((resolve, reject) => {
          preSignedUploadImageToS3(file, setIsLoading, folderName)
            .then((imageData) => {
              setIsLoading(false);
              resolve(imageData);
            })
            .catch((error) => {
              setIsLoading(false);
              NotificationManager.error("Failed to upload image", "Error");
              reject(error);
            });
        });
        
        }else {
        NotificationManager.error(
          "Video size needs to be less than or equal to 50mb",
          "File name: " + file.name
        );
      }
  } else {
    NotificationManager.error("Only video file types are allowed", "Error");
  }
  setIsLoading(false)
}


export const uploadPdfImageToS3 = (file, setIsLoading, foldername, height, width) => {
  const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG , PDF file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }
  
  setIsLoading(true);
  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, setIsLoading, foldername)
      .then((imageData) => {
        setIsLoading(false);
        resolve(imageData);
      })
      .catch((error) => {
        setIsLoading(false);
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};

// Get presigned url
// export const preSignedUploadImageToS3 = async (file, setIsLoader, folderName) => {
//   try {
//    // Generate a 4-digit random number
//    const randomNumber = Math.floor(1000 + Math.random() * 9000);
  
//    // Get current timestamp
//    const timestamp = Date.now();

//     const response = await Axios.post(`api/presigned/generate-presigned-url`, {
//       filePath: `${folderName}/${timestamp}_${randomNumber}_${file.name}`,
//       fileType: file.type
//     });

//     if (response.data.status === false) {
//       NotificationManager.error("Failed to upload file!");
//       setIsLoader(false)
//       return ""
//     } else {
//       const presignedUrl = response.data.data;
//       const uploadResponse = await axios.put(presignedUrl, file, {
//         headers: {
//           'Content-Type': file.type
//         }
//       });

//       if (uploadResponse.status === 200) {
//         const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters

//         setIsLoader(false)
//         return fileUrl;
//       } else {
//         NotificationManager.error("Failed to upload file!");
//         setIsLoader(false)
//         return ""
//       }
//     }
//   } catch (error) {
//     NotificationManager.error("Failed to upload file!");
//     setIsLoader(false)
//     console.error("Error uploading file or generating presigned URL:", error);
//     return ""
//   }
// };


export const preSignedUploadImageToS3 = async (file, setIsLoader, folderName) => {
  try {
    setIsLoader(true);

    const _uploadMode = process.env.REACT_APP_PUBLIC_UPLOAD_MODE;
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const timestamp = Date.now();

    const preSignedUrl = _uploadMode === "AZURE"
      ? `/api/presigned-azure/generate-presigned-url`
      : `/api/presigned/generate-presigned-url`;

    const filePath = `${process.env.REACT_APP_PUBLIC_UPLOAD_DIRECTORY}/${timestamp}_${randomNumber}_${file.name}`;

    // API Call to get presigned URL
    const response = await Axios.post(preSignedUrl, { filePath, fileType: file.type });

    if (!response.data.status) {
      NotificationManager.error("File upload failed!");
      setIsLoader(false);
      return "";
    }

    const presignedUrl = response.data.data;
    const uploadConfig = {
      maxBodyLength: Infinity,
      headers: {
        'Content-Type': file.type,
        ...(_uploadMode === "AZURE" && { 'x-ms-blob-type': 'BlockBlob' })
      }
    };

    // Uploading File to Presigned URL
    const uploadResponse = await axios.put(presignedUrl, file, uploadConfig);

    if ((_uploadMode === "AWS" && uploadResponse.status === 200) ||
      (_uploadMode === "AZURE" && uploadResponse.status === 201 && uploadResponse.statusText === "Created")) {

      const fileUrl = presignedUrl.split('?')[0]; // Remove query parameters

      setIsLoader(false);
      return fileUrl;
    } else {
      NotificationManager.error("Failed to upload file!");
      setIsLoader(false);
      return "";
    }
  } catch (error) {
    NotificationManager.error("File upload failed!");
    setIsLoader(false);
    return "";
  }
};