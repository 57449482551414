/**
 * Router service file
*/

// Async component
import { HulkPageLoader } from 'components/GlobalComponents';
import Loadable from 'react-loadable';

import {
	AsyncFormInformationComponent,
	AsyncUserMasterComponent,
	AsyncWelcomeComponent,
	AsyncSettingComponent,
	AsyncDesignationComponent,
	AsyncDepartmentComponent,
	AsyncAreaComponent,
	AsyncWardComponent,
	AsyncApplicationFormComponent,
	AsyncApplicationReportComponent,
	AsyncCADepDashboardComponent,
	AsyncStatusReportComponent,
	AsyncTalukaMasterComponent,

} from 'routes/ComponentLoader';

const permission = JSON.parse(localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")).authentication : {};
const routes = [

	// Default welcome page
	{
		path: 'welcome',
		component: AsyncWelcomeComponent,
		meta: {
			permission: true,
		},
	},


	// CA Dashboard route and permissions
	{
		path: 'dashboard/ca-department',
		component: AsyncCADepDashboardComponent,
		meta: {
			permission: permission && permission.Dashboard && permission.Dashboard !== undefined
				? permission && permission.Dashboard && permission.Dashboard.includes("Competent_Authority_Dashboard") :
				true,
		},
	},

	//Master's routes & permissions.
	{
		path: 'master/user',
		component: AsyncUserMasterComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("User_Master")
				: false
		},
	},
	{
		path: 'master/formInformation',
		component: AsyncFormInformationComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Form_information_master")
				: false
		},
	},
	{
		path: "settings",
		component: AsyncSettingComponent,
		meta: {
			permission: true
			// permission && permission.CreateScheme !== undefined
			// 	? permission && permission.CreateScheme.includes("Create_Scheme")
			// 	: false
		},
	},
	{
		path: "master/designation",
		component: AsyncDesignationComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Designation_master")
				: false
		},
	},
	{
		path: "master/department",
		component: AsyncDepartmentComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Department_master")
				: false
		},
	},
	{
		path: "master/area",
		component: AsyncAreaComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Area_master")
				: false
		},
	},
	{
		path: "master/ward",
		component: AsyncWardComponent,
		meta: {
			permission: permission && permission.Master !== undefined
				? permission && permission.Master.includes("Ward_master")
				: false
		},
	},
	{
		path: "legal-heir/application-list",
		component: AsyncApplicationFormComponent,
		meta: {
			permission: permission && permission?.TenementForm && permission?.TenementForm !== undefined
				? permission && permission?.TenementForm && permission?.TenementForm?.includes("Application_List") : false
		},
	},
	{
		path: "legal-heir/application-report",
		component: AsyncApplicationReportComponent,
		meta: {
			permission: permission && permission.TenementForm !== undefined
				? permission && permission.TenementForm.includes("Application_Report")
				: false
		},
	},

	{
		path: "application-detail/:id",
		component: Loadable({
			loader: () => import("views/LegalHeirDetail/applicationDetails"),
			loading: () => <HulkPageLoader />,
			delay: 3000,
		}),
		meta: {
			navLink: 'Legal-heir',
			permission: true
			// permission && permission.Master !== undefined
			// 	? permission && permission.Master.includes("Unit_Master")
			// 	: false
		},
	},

	{
		path: "application-report/:id",
		component: Loadable({
			loader: () => import("views/LegalHeir/applicationReport/LegalHeirDetail/applicationDetails"),
			loading: () => <HulkPageLoader />,
			delay: 3000,
		}),
		meta: {
			navLink: 'Legal-heir',
			permission: true
		},
	},

	{
		path: "legal-heir/status-report",
		component: AsyncStatusReportComponent,
		meta: {
			permission: permission && permission.TenementForm !== undefined
				? permission && permission.TenementForm.includes("Status_Report")
				: false
		},
	},
	{
		path: "master/taluka",
		component: AsyncTalukaMasterComponent,
		meta: {
		  permission:
			permission && permission.Master !== undefined
			  ? permission && permission.Master.includes("Taluka_master")
			  : false,
		},
	},

]

export default routes;