/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';


// CA Department Dashboard
const AsyncCADepDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/CA_DepDashboard/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncWelcomeComponent = Loadable({
	loader: () => import("views/Welcome"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Master user //
const AsyncUserMasterComponent = Loadable({
	loader: () => import("views/Master/UserMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("views/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("views/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
	loader: () => import("views/Error/403"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("views/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
	loader: () => import("views/SampleForm"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncFormInformationComponent = Loadable({
	loader: () => import("views/Master/FormInformation/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSettingComponent = Loadable({
	loader: () => import("views/Master/Setting/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncDesignationComponent = Loadable({
	loader: () => import("views/Master/DesignationMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncDepartmentComponent = Loadable({
	loader: () => import("views/Master/DepartmentMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncAreaComponent = Loadable({
	loader: () => import("views/Master/AreaMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncWardComponent = Loadable({
	loader: () => import("views/Master/WardMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncApplicationFormComponent = Loadable({
	loader: () => import("views/LegalHeir/listOfApplication/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncApplicationReportComponent = Loadable({
	loader: () => import("views/LegalHeir/applicationReport/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSchemeDetails = Loadable({
	loader: () => import("views/LegalHeirDetail/applicationDetails"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncStatusReportComponent = Loadable({
	loader: () => import("views/LegalHeir/statusReport/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const AsyncTalukaMasterComponent = Loadable({
	loader: () => import("views/Master/TalukaMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
  });

export {
	AsyncCADepDashboardComponent,
	AsyncUserMasterComponent,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncErrorPage403Component,
	AsyncSampleFormComponent,
	AsyncFormInformationComponent,
	AsyncSettingComponent,
	AsyncWelcomeComponent,
	AsyncDesignationComponent,
	AsyncDepartmentComponent,
	AsyncAreaComponent,
	AsyncWardComponent,
	AsyncApplicationFormComponent,
	AsyncApplicationReportComponent,
	AsyncSchemeDetails,
	AsyncStatusReportComponent,
	AsyncTalukaMasterComponent

};